import Icons8Plus_math from "@/src/components/icons/icons8/icons8PlusMath";
import { cn } from "@/src/lib/utils";
import { useState } from "react";

interface Props {
  title: string;
  content: string;
}
const FaqAccordion: React.FC<Props> = ({ content, title }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="w-full">
      <div
        onClick={() => setOpen(!open)}
        className="w-full cursor-pointer select-none flex justify-between hover:bg-gray-muted hover:bg-opacity-10 items-center py-[25px] border-b border-gray-muted"
      >
        <h3 className=" md:text-lg text-1sm text-dark-primary">{title}</h3>
        <button
          className={cn("duration-500", {
            "rotate-[225deg]": open,
            "rotate-0": !open,
          })}
        >
          <Icons8Plus_math
            className={cn("fill-dark-gray hover:fill-primary-500")}
          />
        </button>
      </div>
      <div
        className={cn("overflow-hidden duration-300", {
          "max-h-0": !open,
          "max-h-[200px] border-b border-gray-muted": open,
        })}
      >
        <p className="py-7.5 lg:text-base text-sm lg:w-[50%] text-gray-primary">{content}</p>
      </div>
    </div>
  );
};
export default FaqAccordion;
