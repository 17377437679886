import FaqAccordion from "./accordion";

const FAQs = () => {
  return (
    <section className="w-full pt-[50px] pb-20 lg:pb-[100px] flex flex-col items-center text-dark-primary ">
      <h2 className="text-2xl sm:text-3xl md:text-4xl tracking-tight">
        <span className="font-display">FAQs</span>
      </h2>
      <div className="max-w-[1024px] w-full px-5 md:px-25 md:mt-0 mt-3.75">
        {mockQuestions.map((q, i) => (
          <FaqAccordion key={i} title={q.title} content={q.content} />
        ))}
      </div>
      <div className="mt-25 w-full h-fit relative">
        <div className="w-full absolute bottom-0 left-0 flex justify-between">
          <div className="w-5 md:w-12.5 aspect-square bg-white"></div>
          <div className="w-5 md:w-12.5 aspect-square bg-white"></div>
        </div>
        <div className="w-full absolute top-0 left-0 flex justify-between">
          <div className="w-5 md:w-12.5 aspect-square bg-white"></div>
          <div className="w-5 md:w-12.5 aspect-square bg-white"></div>
        </div>
        <img
          src="https://storage.googleapis.com/myblankspace-prod/assets/shop-view.png"
          className="w-full h-full object-cover"
        />
      </div>
    </section>
  );
};
export default FAQs;

export const mockQuestions = [
  {
    title: "What is Myblankspace?",
    content:
      "Is an online platform that allows users to book various types of spaces, including Retail, Conference Rooms, Experiences and Events Venues and more.",
  },
  {
    title: "Is there a fee to use SpaceBooker?",
    content:
      "There is a 3% Service fee applied to booking a space on the platform, this helps cover the costs associated with running and maintaining the booking platform, including technology, customer support and processing payments.",
  },
  {
    title: "What payment methods do you accept?",
    content:
      "We accept payments from customers using different payment channels such as: cards, mobile money accounts, Paypal, directly from their bank account.",
  },
  {
    title: "Can I cancel or modify my booking?",
    content:
      "Cancellation policy is determined by the Host. Each Listing specifies the terms for cancellation and any applicable refund. If you cancel a booking, the amount refunded to you is determined by the cancellation policy of the Listing.",
  },
  {
    title: "Will I receive a refund if I cancel my booking?",
    content:
      "MyBlankSpace will initiate any refund within a commercially reasonable time after the cancellation, subject to these Terms and any specific Listing cancellation policy, which may range from flexible to strict.  MyBlankSpace will refund your booking fee should the Host fail to fulfil the booking as agreed.",
  },
];
