const WhyUs = () => {
  return (
    <section className="pt-0 pb-[100px] lg:pb-[200px] px-5 lg:px-25 w-full">
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div>
          <h5 className="text-xs md:text-sm p-2.5 rounded-full border border-gray-muted w-fit text-dark-primary font-medium">
            WHY US
          </h5>
          <p className="mt-6.25 text-3xl lg:text-5xl text-dark-gray w-[80%] md:w-[60%] tracking-tight">
            What sets us apart and makes us the{" "}
            <span className="font-display text-primary-500"> Best Choice</span>{" "}
            for your needs?
          </p>
        </div>
        <div className="grid grid-cols-2 gap-x-5 md:gap-x-12.5 xl:gap-x-25 gap-y-5 md:gap-y-12.5 lg:mt-0 mt-12.5">
          {whyUsInfo.map((i) => (
            <div className="pr-2.5 sm:pr-7.5 md:pr-10">
              <div className="w-12.5 sm:w-15 md:w-16.25">
              {i.illustration}
              </div>
              <h4 className="font-medium sm:text-lg md:text-xl text-dark-primary tracking-tight my-5">{i.title}</h4>
              <p className=" text-dark-gray md:text-base text-1xs sm:text-1sm"> {i.description} </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default WhyUs;

const whyUsInfo = [
  {
    illustration:
      //prettier-ignore
      <svg className="w-full" viewBox="0 0 68 69" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.7" y="0.716113" width="66.6" height="66.6" rx="33.3" fill="white"/>
        <rect x="0.7" y="0.716113" width="66.6" height="66.6" rx="33.3" stroke="#EBEBEB" stroke-width="1.4"/>
        <path d="M34 18.97C31.1875 18.97 29.3031 20.0735 27.606 20.9963C25.9088 21.9192 24.352 22.72 21.5 22.72H20.25V23.97C20.25 33.6173 23.5063 39.8936 26.8076 43.6917C30.109 47.4897 33.5337 48.8796 33.5337 48.8796L33.9976 49.0652L34.459 48.8821C34.459 48.8821 37.8896 47.5089 41.1924 43.7185C44.4951 39.9281 47.75 33.6513 47.75 23.97V22.72H46.5C43.6635 22.72 42.1053 21.9196 40.4038 20.9963C38.7023 20.073 36.8125 18.97 34 18.97ZM34 21.47C36.1875 21.47 37.4586 22.2419 39.2124 23.1936C40.6901 23.9954 42.6389 24.7523 45.1646 25.0125C44.9286 33.4746 42.1311 38.8351 39.3076 42.0754C36.7427 45.0191 34.5997 46.0387 34.0024 46.304C33.4037 46.0346 31.2578 45.0025 28.6924 42.051C25.8681 38.8018 23.0746 33.4406 22.8379 25.0125C25.372 24.7524 27.3244 23.9934 28.7998 23.1912C30.5508 22.239 31.8125 21.47 34 21.47ZM34 26.5164L32.2935 30.4128L28.0063 30.8694L31.0898 33.6135L30.2476 37.8591L34 35.6936L37.7524 37.8591L36.9102 33.6135L39.9937 30.8694L35.7065 30.4128L34 26.5164Z" fill="#2AA58F"/>
        </svg>,
    title: "Secure Payments",
    description:
      "Our secure payment system ensures that your financial information is protected at all times.",
  },
  {
    illustration:
      //prettier-ignore
      <svg className="w-full" viewBox="0 0 68 69" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.7" y="0.716113" width="66.6" height="66.6" rx="33.3" fill="white"/>
        <rect x="0.7" y="0.716113" width="66.6" height="66.6" rx="33.3" stroke="#EBEBEB" stroke-width="1.4"/>
        <path d="M34 20.188L19.8203 25.3442L19 25.6567V45.2661H26.5V42.7661H21.5V27.3755L34 22.8442L46.5 27.3755V42.7661H41.5V45.2661H49V25.6567L48.1797 25.3442L34 20.188ZM27.75 30.2661V48.5474L34 45.4224L40.25 48.5474V30.2661H27.75ZM30.25 32.7661H37.75V44.4849L34 42.6099L30.25 44.4849V32.7661Z" fill="#2AA58F"/>
        </svg>,
    title: "Seemless Booking",
    description:
      "Our user-friendly interface allows you to search, compare, and book spaces in just a few clicks",
  },
  {
    illustration:
      //prettier-ignore
      <svg className="w-full" viewBox="0 0 68 69" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.7" y="0.716113" width="66.6" height="66.6" rx="33.3" fill="white"/>
        <rect x="0.7" y="0.716113" width="66.6" height="66.6" rx="33.3" stroke="#EBEBEB" stroke-width="1.4"/>
        <path d="M34.0024 17.2778L33.1333 18.1177L29.8618 21.2793L24.1611 20.4736L23.1675 26.145L18.082 28.8428L20.6089 34.0161L18.082 39.1895L23.1675 41.8872L24.1611 47.5586L29.8618 46.7529L34.0024 50.7544L38.1382 46.7529L43.8389 47.5586L44.835 41.8872L49.9204 39.1895L47.3936 34.0161L49.9204 28.8428L44.835 26.145L43.8389 20.4736L38.1382 21.2793L34.0024 17.2778ZM34 20.7544L37.2788 23.9258L41.7954 23.2886L42.584 27.7808L46.6123 29.9194L44.6104 34.0161L46.6123 38.1128L42.584 40.2515L41.7954 44.7437L37.2788 44.1064L34 47.2778L30.7236 44.1064L26.207 44.7437L25.4185 40.2515L21.3901 38.1128L23.3921 34.0161L21.3901 29.9194L25.4185 27.7808L26.207 23.2886L30.7236 23.9258L34 20.7544ZM39.3369 28.9697L31.5 36.8066L27.3838 32.6904L25.6162 34.458L31.5 40.3418L41.1045 30.7373L39.3369 28.9697Z" fill="#2AA58F"/>
        </svg>,
    title: "Verified Listings",
    description:
      "Each space is thoroughly checked and approved, so you can trust that what you see is what you get.",
  },
  {
    illustration:
      //prettier-ignore
      <svg className="w-full" viewBox="0 0 68 69" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.7" y="0.716113" width="66.6" height="66.6" rx="33.3" fill="white"/>
        <rect x="0.7" y="0.716113" width="66.6" height="66.6" rx="33.3" stroke="#EBEBEB" stroke-width="1.4"/>
        <path d="M34 17.1411L32.9062 19.1333L28.2578 27.6099L18.7656 29.4067L16.5391 29.8364L18.1016 31.5161L24.7031 38.5474L23.4922 48.1177L23.2188 50.3833L25.25 49.4067L34 45.3442L42.75 49.4067L44.7812 50.3833L44.5078 48.1177L43.2969 38.5474L49.8984 31.5161L51.4609 29.8364L49.2344 29.4067L39.7422 27.6099L35.0938 19.1333L34 17.1411ZM34 22.3364L37.8281 29.3286L38.1406 29.8364L38.6875 29.9536L46.5391 31.438L41.0703 37.2583L40.6797 37.688L40.7578 38.2739L41.7734 46.2036L34.5469 42.8052L34 42.5317L33.4531 42.8052L26.2266 46.2036L27.2422 38.2739L27.3203 37.688L26.9297 37.2583L21.4609 31.438L29.3125 29.9536L29.8594 29.8364L30.1719 29.3286L34 22.3364ZM32.75 30.2661V34.0161H29V36.5161H32.75V40.2661H35.25V36.5161H39V34.0161H35.25V30.2661H32.75Z" fill="#2AA58F"/>
        </svg>,
    title: "Trusted Reviews",
    description:
      "Read reviews from real users who have booked and experienced the spaces.",
  },
];
