import { FC, useState } from "react";
import LogoDarkFull from "../../../icons/logo-dark-full";
import { Button } from "../../../ui/button";
import { cn } from "@/src/lib/utils";
import Router, { useRouter } from "next/router";
import Link from "next/link";
import { useSession } from "next-auth/react"; // Import NextAuth hook
import { LayoutDashboard } from "lucide-react";

const Nav = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const { data: session, status } = useSession(); // Get session data and status

  // Check if the user is authenticated
  const isAuthenticated = status === "authenticated";

  return (
    <div className="w-full h-[80px] flex bg-transparent relative z-10">
      <NavEdge />
      <div className="flex-1 bg-white h-[65px] flex justify-between items-center px-5 lg:px-6.25 relative shadow-sm z-10">
        <div className="w-[150px]">
          <LogoDarkFull className="h-[40px] md:h-[45px]" />
        </div>
        <Links className="hidden lg:block" />
        <div className="w-[150px] justify-end flex gap-2.5 items-center">
          {isAuthenticated ? (
            // Show Dashboard button when authenticated
            <Button
              className="flex items-center gap-2 mr-4 rounded-full primary-gradient h-7.5 px-2.5 md:h-10"
              onClick={() => Router.push("/dashboard")}
            >
              <LayoutDashboard className="w-4 h-4" />
              DASHBOARD
            </Button>
          ) : (
            // Show Login and Sign Up buttons when not authenticated
            <>
              <Button
                className="p-1.25 md:p-2.5 rounded-full"
                variant={"ghost"}
                onClick={() => Router.push("/auth/signin")}
              >
                LOGIN
              </Button>
              <Button
                className="rounded-full primary-gradient h-7.5 px-2.5 md:h-10"
                onClick={() => Router.push("/auth/signup")}
              >
                SIGN UP
              </Button>
            </>
          )}
          <button
            onClick={() => setShowMobileNav(!showMobileNav)}
            className="lg:hidden block"
          >
            {!showMobileNav ? (
              //prettier-ignore
              <svg width="25" height="6" viewBox="0 0 26 6" fill="none">
                <path d="M0.358398 0.535645H25.3584" stroke="black"/>
                <path d="M0.358398 5.41321H25.3584" stroke="black"/>
              </svg>
            ) : (
              //prettier-ignore
              <svg width="25" height="19" viewBox="0 0 20 19" fill="none">
                <path d="M1.01953 0.696899L18.6972 18.3746" stroke="black"/>
                <path d="M1.01953 18.3745L18.6972 0.69684" stroke="black"/>
              </svg>
            )}
          </button>
        </div>
      </div>
      <MobileNav show={showMobileNav}>
        <Links className="" />
      </MobileNav>
      <NavEdge />
    </div>
  );
};
export default Nav;

// Rest of the components (NavEdge, Links, MobileNav) remain unchanged
export const NavEdge = () => {
  return (
    <div className="w-12.5 h-full shadow-inner bg-white lg:block hidden">
      <div className="h-5 bg-[#131D19] w-full"></div>
    </div>
  );
};

export const Links = (props: { className?: string }) => {
  const router = useRouter();
  return (
    <div
      className={cn(
        "flex lg:flex-row flex-col lg:gap-0 gap-3.75",
        props.className
      )}
    >
      {linkData.map((item, index) => {
        return (
          <Link
            key={index}
            href={item.link}
            className={`w-[150px] inline-block lg:text-center text-xs md:text-1xs font-medium  ${item.link.includes(router.pathname) ? 'text-green-text' : 'text-dark-gray'}`}
          >
            {item.title}
          </Link>
        );
      })}
    </div>
  );
};

export const MobileNav = (props: {
  show: boolean;
  children?: React.ReactNode;
}) => {
  return (
    <div
      className={cn(
        "w-full lg:hidden bg-white duration-500 absolute overflow-hidden top-[65px]",
        {
          "max-h-[300px]": props.show,
          "max-h-0": !props.show,
        }
      )}
    >
      <div className="py-10 px-5">{props.children}</div>
    </div>
  );
};

const linkData = [
  {
    title: "HOW IT WORKS ↗",
    link: "",
    onclick: () => {},
  },
  {
    title: "LOCATION PROFILES ↗",
    link: "/location-profile",
    onclick: () => {},
  },
  {
    title: "MAGAZINE ↗",
    link: "",
    onclick: () => {},
  },
];